<template >
  <div class="containter">
    <div class="feedbackTypeArea">
      <div class="height13"></div>
      <div class="feedbackTypeTitle">
        <div class="marginLeft12">咨询类型</div>
      </div>
      <div class="feedbackTypeSelect">
        <van-radio-group v-model="feedbackTypeSelected" direction="horizontal">
          <van-radio name="0" checked-color="#ff192f">法律咨询</van-radio>
          <van-radio name="1" checked-color="#ff192f">政策咨询</van-radio>
          <van-radio name="2" checked-color="#ff192f">项目咨询</van-radio>
          <van-radio name="3" checked-color="#ff192f">专业咨询</van-radio>
        </van-radio-group>
      </div>
    </div>
    <div class="commentArea">
      <van-field
        v-model="commentText"
        rows="5"
        show-word-limit
        maxlength="140"
        autosize
        type="textarea"
        placeholder="请输入您想评论的内容"
      />
      <div class="imgLine">
         <!-- :max-count="3"
          multiple -->
        <van-uploader
          class="marginLeft12"
          v-model="fileList"
          :before-read="beforeRead"
          :after-read="afterRead"
          upload-text="添加图片"
         :max-count="3"
          multiple
        >
          <!-- <img :src="addImg" class="addImg" /> -->
        </van-uploader>
      </div>
    </div>
    <div class="submitButton" @click="submit">提交</div>
  </div>
</template>

<script>
import { Toast, Notify } from 'vant';
import { saveFeedBack, uploadFile } from '@/api/feedback';

export default {
  name: 'lifeConsultationWantTo',

  data() {
    return {
      feedbackTypeSelected: '0',
      id: '',
      commentText: '',
      fileList: []
    };
  },
  watch: {},
  destroyed() {
    // window.removeEventListener('hashchange', this.afterQRScan)
  },
  methods: {
    // 上传前校验
    beforeRead(file) {
      console.log(this.fileList);
      if (Array.isArray(file)) {
        let notType = false;
        file.forEach(item => {
          if (
            item.type !== 'image/jpeg' &&
            item.type !== 'image/png' &&
            item.type !== 'image/gif'
          ) {
            notType = true;
          }
        });
        if (notType) {
          Notify({ type: 'warning', message: '请上传 jpg/png/gif格式图片' });
          return false;
        }
        if (file.length > 3 - this.fileList.length) {
          Notify({
            type: 'warning',
            message: '您上传的张数超过上限，请重新选择'
          });
          return false;
        }
      } else {
        if (
          file.type !== 'image/jpeg' &&
          file.type !== 'image/png' &&
          file.type !== 'image/gif'
        ) {
          Notify({ type: 'warning', message: '请上传 jpg/png/gif格式图片' });
          return false;
        }
      }

      return true;
    },
    // 上传服务器
    afterRead(file) {
      const this_ = this;
      if (Array.isArray(file)) {
        file.forEach(fileItem => {
          console.log(fileItem, this.fileList);
          fileItem.status = 'uploading';
          fileItem.message = '上传中...';
          const formdata = new FormData();
          formdata.append('file', file.file);
          lrz(fileItem.file, {})
            .then(async function(rst) {
              //成功时执行
              console.log('压缩成功');
              const formdata = rst.formData;
              await uploadFile(formdata)
                .then(res => {
                  res = res.data;
                  if (res.success) {
                    fileItem.status = 'done';
                    fileItem.url = res.data.link;
                    Notify({ type: 'success', message: '上传成功' });
                  } else {
                    fileItem.status = 'failed';
                    fileItem.message = '上传失败';
                    Notify({ type: 'warning', message: '上传失败' });
                  }
                })
                .catch(err => {
                  fileItem.status = 'failed';
                  fileItem.message = '上传失败';
                  Notify({ type: 'warning', message: '上传失败' });
                  console.log(err);
                });
            })
            .catch(function(error) {
              Notify({ type: 'warning', message: '图片压缩失败' });
              //失败时执行
            })
            .always(function() {
              //不管成功或失败，都会执行
            });
        });
      } else {
        console.log(file, this.fileList);
        file.status = 'uploading';
        file.message = '上传中...';
        // const formdata = new FormData();
        // formdata.append('file', file.file);
        lrz(file.file, {})
          .then(function(rst) {
            //成功时执行
            console.log('压缩成功');
            const formdata = rst.formData;
            uploadFile(formdata)
              .then(res => {
                res = res.data;
                if (res.success) {
                  file.status = 'done';
                  file.url = res.data.link;
                  Notify({ type: 'success', message: '上传成功' });
                } else {
                  this_.fileList = this_.fileList.slice(
                    0,
                    this_.fileList.length - 1
                  );
                  Notify({ type: 'warning', message: '上传失败' });
                }
              })
              .catch(err => {
                this_.fileList = this_.fileList.slice(
                  0,
                  this_.fileList.length - 1
                );
                Notify({ type: 'warning', message: '上传失败' });
                console.log(err);
              });
          })
          .catch(function(error) {
            Notify({ type: 'warning', message: '图片压缩失败' });
            //失败时执行
          })
          .always(function() {
            //不管成功或失败，都会执行
          });
      }
    },
    submit() {
      // const contentImg = this.fileList.map(item => item.url).join(',');
      const fileList = this.fileList;
      let imgString = [];
      fileList.forEach(item => {
        if ((item.status = 'done')) {
          imgString.push(item.url);
        }
      });
      const imgArray = [];
      imgString.forEach(item => {
        if (item) {
          imgArray.push(item);
        }
      });
      if (!this.commentText) {
        Notify({ type: 'warning', message: '咨询文字不能为空' });
        return;
      }
      //提交
      saveFeedBack({
        dtype: 0,
        content: this.commentText,
        contentImg: imgArray.join(','),
        mattertype: this.feedbackTypeSelected
      }).then(res => {
        if (res.success) {
          Toast('提交成功');
          setTimeout(() => {
            this.$router.go(-1);
          }, 500);
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
$px: 1rem/37.5;
/deep/ .van-radio--horizontal {
  margin-right: 50 * $px;
  margin-bottom: 13 * $px;
}
html body {
  width: 100%;
  height: 100%;
}
.containter {
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
  overflow-y: auto;
  position: relative;
}
.commentArea {
  width: 100%;
  height: 265 * $px;
  background-color: #ffffff;
  position: relative;
}
.imgLine {
  width: 100%;
  height: 90 * $px;
  position: absolute;
  bottom: 10 * $px;
  left: 0px;
}
.marginLeft12 {
  margin-left: 12 * $px;
}
.addImg {
  width: 80 * $px;
  height: 80 * $px;
}
.submitButton {
  margin: 30 * $px 15 * $px;
  width: 345 * $px;
  height: 44 * $px;
  border-radius: 22 * $px;
  text-align: center;
  line-height: 44 * $px;
  background-color: #ff192f;
  color: #ffffff;
  font-size: 16 * $px;
}
.feedbackTypeArea {
  width: 100%;
  height: 70 * $px;
  background-color: #ffffff;
  margin-bottom: 10 * $px;
}
.feedbackTypeTitle {
  width: 100 * $px;
  height: 100%;
  float: left;
  color: #555555;
  font-size: 14 * $px;
}
.marginLeft12 {
  margin-left: 12 * $px;
}
.height13 {
  height: 11 * $px;
  width: 100%;
  float: left;
}
.feedbackTypeSelect {
  width: 275 * $px;
  float: left;
  height: 100%;
  color: #555555;
  font-size: 14 * $px;
}
</style>



